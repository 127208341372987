<template>
  <a-page-header @back="() => $router.go(-1)" title="EXPORTAR BASE DE DATOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Import./Export.</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Exportar base de datos</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid w-full text-center border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto"
    >
      <legend class="text-sm text-center">Acciones</legend>
      <button @click="downloadSQL" type="button" class="text-center px-3">
        <div class="text-3xl text-green-600">
          <DownloadOutlined />
        </div>
        <p>Exportar</p>
      </button>
    </fieldset>
  </div>

  <a-spin tip="Descargando Información..." :spinning="spinning">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="true"
      rowKey="idbasedatos"
      :loading="isLoading"
      :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ record }">
        <span> {{ data.indexOf(record) + 1 }} </span>
      </template>
    </a-table>
  </a-spin>
</template>

<script>
import { DownloadOutlined } from "@ant-design/icons-vue";
import { ref, reactive, computed, toRaw } from "vue";
import { columns, data } from "./utilsSivinur";
import ApiBackup from "@/api/backup";

export default {
  components: {
    DownloadOutlined
  },
  setup() {
    const spinning = ref(false);
    const exportar = ref(null);

    const state = reactive({
      selectedRowKeys: []
    });

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          exportar.value = toRaw(selectedRows[0]);
        }
      };
    });

    // const customRow = () => {
    //   return {
    //     onDblclick: () => {
    //       clearSelectedArrays();
    //     }
    //   };
    // };

    const downloadSQL = () => {
      var ids = toRaw(state.selectedRowKeys);
      if (ids == 1) {
        spinning.value = true;
        ApiBackup.getBackupSivinur()
          .then(() => {
            spinning.value = false;
          })
          .catch(() => (spinning.value = false));
      } else if (ids == 2) {
        spinning.value = true;
        ApiBackup.getBackupConfiguracion()
          .then(() => {
            spinning.value = false;
          })
          .catch(() => (spinning.value = false));
      } else if (ids == 3) {
        spinning.value = true;
        ApiBackup.getBackupMigrate()
          .then(() => {
            spinning.value = false;
          })
          .catch(() => (spinning.value = false));
      } else if (ids == 4) {
        spinning.value = true;
        ApiBackup.getBackupDataHouse()
          .then(() => {
            spinning.value = false;
          })
          .catch(() => (spinning.value = false));
      }
    };

    return {
      spinning,
      columns,
      exportar,
      downloadSQL,
      // customRow,
      rowSelection,
      data
    };
  }
};
</script>

<style></style>

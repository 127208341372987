export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE DE BASE DE DATOS",
    dataIndex: "nombre",
    key: "nombre",
    width: 120
  }
];

export const data = [
  {
    idbasedatos: 1,
    nombre: "BASE DE DATOS SIVINUR"
  },
  {
    idbasedatos: 2,
    nombre: "BASE DE DATOS SEGURIDAD"
  },
  {
    idbasedatos: 3,
    nombre: "BASE DE DATOS MIGRATE"
  },
  {
    idbasedatos: 4,
    nombre: "BASE DE DATOS DATA HOUSE"
  }
];
